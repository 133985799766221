
import { defineComponent } from "vue";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import CodeHighlighter2 from "@/components/highlighters/CodeHighlighter2.vue";
import {
  example4Js1,
  example4Html,
  example4Js2
} from "@/views/resources/documentation/forms/vee-validate/codes";

export default defineComponent({
  name: "example-4",
  components: {
    CodeHighlighter,
    CodeHighlighter2
  },
  setup() {
    // Define a validation schema
    const schema = yup.object({
      email: yup
        .string()
        .required()
        .email(),
      password: yup
        .string()
        .required()
        .min(8)
    });

    // Create a form context with the validation schema
    useForm({
      validationSchema: schema
    });

    // No need to define rules for fields
    const { value: email, errorMessage: emailError } = useField("email");
    const { value: password, errorMessage: passwordError } = useField(
      "password"
    );

    return {
      email,
      emailError,
      password,
      passwordError,
      example4Js1,
      example4Html,
      example4Js2
    };
  }
});
