
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Overview from "@/views/resources/documentation/forms/vee-validate/Overview.vue";
import Example1 from "@/views/resources/documentation/forms/vee-validate/Example1.vue";
import Example2 from "@/views/resources/documentation/forms/vee-validate/Example2.vue";
import Example3 from "@/views/resources/documentation/forms/vee-validate/Example3.vue";
import Example4 from "@/views/resources/documentation/forms/vee-validate/Example4.vue";

export default defineComponent({
  name: "vee-validate",
  components: {
    Overview,
    Example1,
    Example2,
    Example3,
    Example4
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("VeeValidate");
    });
  }
});
